const canvas = document.createElement('canvas');
let allBees = [];
const ctx = canvas.getContext('2d');
ctx.textAlign = "center";
ctx.textBaseline = "middle";
ctx.font = "20px courier";

function makeSwarms(swarms, debug) {
  return new Promise(function(resolve, reject) {
    resolve(swarms.map(
      ({ x, y, scale, segments }) => makeSwarm(x, y, scale, segments, debug)
    ));
  });
}

function makeSwarm(centerX, centerY, scale, segments, debug) {
  const container = document.createElement('div');
  container.classList.add('swarm');
  container.style.top = centerY+"%";
  container.style.left = centerX+"%";
  segments.map(s => ({ scale, debug, ...s})).map(makeBlob).forEach(function(blob) {
    container.appendChild(blob);
  });
  return container;
}

function makeBlob({ scale = 1, size = 300, population = 150, clustering = -2, offset = 0, debug = false }) {
  canvas.width = size;
  canvas.height = size;
  const radius = size/2;

  const ps = makeRandomPoints(population, clustering);
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  if (debug) {
    ctx.strokeStyle = "white";
    ctx.setLineDash([1, 4]);
  }
  ps.forEach(function(point) {
    ctx.save();
    ctx.translate(radius, radius);
    ctx.rotate(point.angle);
    ctx.translate(point.radius * radius * 0.9, 0);
    ctx.rotate(rotationJitter() - point.angle);
    const flip = Math.random() > 0.5 ? -1 : 1;
    ctx.scale(flip * scale, scale);
    ctx.fillText("🐝", 0, 0);
    ctx.restore();
    if (debug) {
      ctx.strokeRect(0, 0, size, size);
      ctx.beginPath();
        ctx.arc(size/2-5, size/2-5, 10, 0, 2 * Math.PI);
      ctx.stroke();
    }
  });
  const image = new Image();
  image.src = canvas.toDataURL();
  image.classList.add('bees');
  const wrapper = document.createElement('div');
  wrapper.classList.add('bees-wrapper');
  const offsetAngle = Math.random() * Math.PI * 2;
  wrapper.style.top = Math.floor(Math.sin(offsetAngle) * offset) + "px";
  wrapper.style.left = Math.floor(Math.cos(offsetAngle) * offset) + "px";
  wrapper.appendChild(image);
  return wrapper;
}

function makeRandomPoints(count, clustering) {
  const arr = new Array(count);
  const clusterModifier = typeof clustering === "number"
    ? Math.max(Math.min(clustering, 0), -3.0)
    : -2.5;
  for (let i = 0; i < count; i++) {
    arr[i] = randomPoint(clusterModifier);
  }
  return arr;
}

function randomPoint(clustering) {
  return {
    angle: Math.random() * Math.PI * 2,
    radius: Math.pow(clustering * Math.log(Math.random()), 0.5) / 3,
  };
}

function rotationJitter() {
  const amount = Math.PI / 5;
  return amount - Math.random() * amount * 2;
}

function jitterElement(element) {
  const angle = Math.random() * Math.PI * 2;
  const x = Math.floor(Math.cos(angle) * 15);
  const y = Math.floor(Math.sin(angle) * 15);
  element.style = `transform: translate(${x}px, ${y}px);`
}

let jitterHandle;
let nextJitter = 0;
function jitterAllBees(ts) {
  if (ts && ts >= nextJitter) {
    allBees.forEach(jitterElement);
    nextJitter = ts + 100;
  }
  jitterHandle = window.requestAnimationFrame(jitterAllBees);
}

function startSwarms() {
  allBees = Array.prototype.slice.call(document.querySelectorAll(".bees"));
  window.requestAnimationFrame(jitterAllBees);
}
function stopSwarms() {
  window.cancelAnimationFrame(jitterHandle);
  jitterHandle = undefined;
}
function toggleSwarms() {
  jitterHandle ? stopSwarms() : startSwarms();
}

const domContentLoaded = new Promise(function(resolve, reject) {
  if (document.readyState !== "complete") {
    resolve();
  } else {
    document.addEventListener('DOMContentLoaded', resolve);
  }
});

export {
  domContentLoaded,
  makeSwarms,
  startSwarms,
  stopSwarms,
  toggleSwarms,
}
