import {
  domContentLoaded,
  makeSwarms,
  startSwarms,
  toggleSwarms,
} from '../swarm';

import './app.css';

const debug = !!location.search.match(/\bdebug=(?:true|1)\b/);
let sceneOverride;
try {
  sceneOverride = parseInt(location.search.match(/\bscene=(\d+)/)[1], 10);
} catch(e) {}

const beesCookie = /\bbees=([\d,]+);?/;
function readCookie() {
  try {
    const match = document.cookie.match(beesCookie);
    return match ? match[1].split(',') : [];
  } catch(e) {
    return [];
  }
}

function writeCookie(seen) {
  try {
    document.cookie = `bees=${seen === null ? "" : seen.join()};`;
  } catch (e) {}
}

function appendApp(parent, style, image, swarms) {
  document.head.appendChild(style);
  swarms.forEach(swarm => image.appendChild(swarm));
  parent.insertBefore(image, parent.firstChild);
  // document.querySelector('.help').addEventListener('click', toggleSwarms);
}

function pictureTemplate(basename) {
  return (`<picture>
  <source srcset="/i/${basename}.webp" type="image/webp">
  <img src="/i/${basename}.jpg" class="background">
</picture>`);
};

function fetchImage(fileName) {
  return new Promise(function(resolve, reject) {
    const background = document.createElement('div');
    background.classList.add("figure");
    background.innerHTML = pictureTemplate(fileName);
    const image = background.querySelector('img');
    image.onload = function(e) {
      const style = makeCoverStyle(image.naturalWidth, image.naturalHeight);
      resolve({ style, background });
    }
    image.onerror = reject;
  });
}

function makeCoverStyle(w, h) {
  const style = document.createElement('style');
  style.innerHTML = `@media(max-aspect-ratio:${w}/${h}){.figure {width:${Math.ceil(w/h*100)}vh;height:100vh;}}@media(min-aspect-ratio:${w}/${h}){.figure{width:100vw;height:${Math.ceil(h/w*100)}vw;}}`;
  return style;
}

function pickScene(arr, seen=[]) {
  return ( Number.isInteger(sceneOverride) &&
    arr[sceneOverride] || sampleScene(arr));
}

function sampleScene(arr) {
  const seen = readCookie();
  const remaining = Object.keys(arr).filter(i => !seen.includes(i));
  let index;
  if (remaining.length) {
    index = remaining[Math.floor(Math.random() * remaining.length)];
    writeCookie(seen.concat(index));
  } else {
    index = Math.floor(Math.random() * arr.length);
    writeCookie([index]);
  }
  return arr[index];
}

const scenes = [
  { image: "20180724_124719",
    swarms: [
      { x: 70, y: 60, segments: [
        { size: 600, population: 150, clustering: -1 },
        { size: 600, population: 150, clustering: -1 },
        { size: 300, population: 25, clustering: -3, offset: 30 },
        { size: 300, population: 25, clustering: -3, offset: 30 },
        { size: 200, population: 8, clustering: -2.5, offset: 75 },
        { size: 200, population: 8, clustering: -2.5, offset: 75 },
        { size: 200, population: 8, clustering: -2.5, offset: 75 },
      ] },
    ] },
  { image: "20180828_171427",
    swarms: [
      { x: 40, y: 25, segments: [
        { size: 400, population: 50, clustering: -1 },
        { size: 400, population: 50, clustering: -1 },
        { size: 400, population: 50, clustering: -2 },
      ] },
      { x: 60, y: 70, segments: [
        { size: 200, population: 20, clustering: -2 },
        { size: 200, population: 20, clustering: -2 },
        { size: 200, population: 20, clustering: -2 },
      ] },
    ] },
  { image: "20181121_171657",
    swarms: [
      { x: 45, y: 48, segments: [
        { size: 100, population: 5, clustering: -4, offset: 0 },
        { size: 100, population: 5, clustering: -4, offset: 10 },
        { size: 100, population: 5, clustering: -4, offset: 10 },
      ] },
      { x: 75, y: 20, segments: [
        { size: 200, population: 15, clustering: -3, offset: 0 },
        { size: 200, population: 15, clustering: -3, offset: 5 },
        { size: 200, population: 15, clustering: -3, offset: 5 },
      ] },
    ] },
  { image: "20190415_183653",
    swarms: [
      { x: 85, y: 70, segments: [
        { size: 400, population: 10, clustering: -3, offset: 0 },
        { size: 400, population: 10, clustering: -3, offset: 10 },
        { size: 400, population: 10, clustering: -3, offset: 15 },
      ] },
      { x: 45, y: 50, scale: 0.6, segments: [
        { size: 200, population: 15, clustering: -2, offset: 0 },
        { size: 200, population: 15, clustering: -2, offset: 10 },
        { size: 150, population: 25, clustering: -10, offset: 10 },
        { size: 150, population: 35, clustering: -10, offset: 20 },
      ] },
    ] },
  // { image: "20180617_190101",
  //   swarms: [
  //     { x: 80, y: 20, segments: [
  //       { size: sizeRelative(1.2), population: 800, clustering: -0.75 },
  //       { size: 400, population: 100, clustering: -2.5, offset: 50 },
  //       { size: 400, population: 100, clustering: -2.5, offset: 50 },
  //       { size: 200, population: 25, clustering: -3, offset: 75 },
  //       { size: 200, population: 25, clustering: -3, offset: 75 },
  //     ] },
  //     { x: 50, y: 60, segments: [
  //       { size: sizeRelative(1.2), population: 800, clustering: -0.75 },
  //       { size: 400, population: 100, clustering: -2.5, offset: 50 },
  //       { size: 400, population: 100, clustering: -2.5, offset: 50 },
  //       { size: 200, population: 25, clustering: -3, offset: 75 },
  //       { size: 200, population: 25, clustering: -3, offset: 75 },
  //     ] },
  //   ] },
  // { image: "20180704_192731",
  //   swarms: [
  //     { x: 50, y: 30, segments: [
  //       { size: sizeRelative(0.8, 600), population: 300, clustering: -0.5 },
  //       { size: 300, population: 50, clustering: -2.5, offset: 75 },
  //       { size: 300, population: 50, clustering: -2.5, offset: 75 },
  //       { size: 300, population: 50, clustering: -2.5, offset: 75 },
  //     ] },
  //   ] },
  // { image: "20170614_105839",
  // swarms: [
  //   { x: 33, y: 45, segments: [
  //     { size: sizeRelative(0.5, 400), population: 150 },
  //     { size: sizeRelative(0.25, 200), population: 50, clustering: -3, offset: 100 },
  //     { size: sizeRelative(0.25, 200), population: 50, clustering: -3, offset: 100 },
  //   ] },
  //   { x: 73, y: 55, segments: [
  //     { size: sizeRelative(0.5, 300), population: 100, offset: 20 },
  //     { size: sizeRelative(0.5, 300), population: 100, offset: 20 },
  //     { szie: sizeRelative(0.3, 150), population: 40, clustering: -2.5, offset: 40 },
  //   ] },
  // ] },
];

function start() {
  const scene = pickScene(scenes);
  return Promise.all([fetchImage(scene.image), makeSwarms(scene.swarms, debug), domContentLoaded])
  .then(([ { style, background }, swarms, _ ]) =>
    appendApp(document.body, style, background, swarms))
  .then(startSwarms)
  .catch(e => console.error("Yikes, can't start", e));
}

start();
